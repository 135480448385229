import React from "react";
import styled from "styled-components";

import { Container, P } from "@util/standard";
import { Maybe, SanityHero } from "@graphql-types";
import { colors } from "@util/constants";

const Wrapper = styled(Container)`
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: ${colors.flightBlue};
  justify-content: center;
`;

const Icon = styled(Container)`
  position: absolute;
  width: 22px;
  height: 36px;
  bottom: 50px;
  cursor: pointer;
  z-index: 2;
`;

const Content = styled(Container)`
  color: ${colors.white};
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  opacity: 1;
  align-items: center;
  text-align: center;

  h1 {
    color: #fff;
    font-size: 100px;
  }

  p {
    width: 50%;
    margin: 10px auto;
    color: #fff;
  }
`;

const AnimatedJeff = styled.p`
  @keyframes example {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  animation-name: example;
  animation-duration: 8s;
  color: #fff;
  opacity: 0;
`;

interface Props {
  heroData: Maybe<SanityHero> | undefined;
}

const Hero = ({ heroData }: Props) => {
  if (heroData == null) {
    return <P color="errorRed">Error loading hero</P>;
  }

  const handleScroll = () => {
    if (typeof window !== undefined) {
      const scrollValue = window.innerHeight;
      window.scrollTo({ top: scrollValue, behavior: "smooth" });
    }
    return;
  };

  return (
    <Wrapper>
      <Content flexDirection="column">
        <h1>Welcome to Flight.</h1>
        <p>
          We’re so glad you're here. Over the next few days you’ll be meeting various members of the
          wider team who’ll tell you more about their roles and how they can help you with yours. We
          won't expect you to remember each of their names but we promise they will know yours.
        </p>
      </Content>
    </Wrapper>
  );
};

export default Hero;
