import React, { useState } from "react";
import styled from "styled-components";
import Masonry from "@mui/lab/Masonry";
import { Link, graphql, PageProps, useStaticQuery } from "gatsby";

import { Container, TextButton } from "@util/standard";
import Hero from "@shared/hero";
import { useCategoryQuery, useHomeQuery } from "@api";
import { assets, fontSizes, MOBILE_BREAKPOINT } from "@util/constants";
import { BlogInnerPreviewContent } from "@global";
import { useCheckScreenWidth } from "@util/hooks";
import { Query } from "@graphql-types";

const Dot = styled.img`
  width: 8px;
  height: 8px;
  margin: auto 10px auto auto;
`;

const Content = styled(Container)`
  margin-top: 70px;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 30px;
  }
`;

const StyledMasonry = styled(Masonry)`
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    &.MuiMasonry-root {
      margin: 0;
    }
  }
`;

const FilterContainer = styled(Container)`
  width: 100%;
  margin: 0 0 20px 0;
  flex-wrap: wrap;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 95%;
    margin: auto;
  }
`;

const Articles = styled(Container)`
  width: 80%;
  margin: 150px auto;
  flex-direction: column;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 50px auto;
    width: 90%;
  }
`;

const NUMBER_TO_LOAD = 10;

export default function IndexPage() {
  const { allSanityBlogInner }: Query = useStaticQuery(graphql`
    {
      allSanityBlogInner(sort: { fields: order, order: ASC }) {
        nodes {
          title
          _key
          _id
          slug {
            current
          }
          previewMedia {
            ... on SanityHexColors {
              ...sanityHexColors
            }
            ... on SanityImageWithMeta {
              ...sanityImageWithMeta
            }
          }
          category {
            _key
            category {
              ...sanityCategory
            }
          }
        }
      }
    }
  `);

  const homeQuery = useHomeQuery();
  const categoryType = useCategoryQuery();
  const { nodes: allBlogs } = allSanityBlogInner;
  const { isMobileWidth, isTabletWidth } = useCheckScreenWidth();

  const filters = ["All", ...categoryType.map(type => type?.node?.categoryTitle)];
  const [filtered, setFiltered] = useState("All");
  const [noOfItemsToLoad, setNoOfItemsToLoad] = useState(NUMBER_TO_LOAD);

  const filterBlogs =
    allBlogs &&
    allBlogs.filter(blog => {
      return (
        blog.category &&
        blog.category.filter(type => type && type.category?.categoryTitle == filtered).length > 0
      );
    });

  if (homeQuery == null) {
    return null;
  }

  const handleLoadMoreClick = () => {
    setNoOfItemsToLoad(noOfItemsToLoad + NUMBER_TO_LOAD);
  };

  const FilterButton = () => {
    return (
      <FilterContainer>
        {filters &&
          filters.map((filter, index) => {
            if (filter == null) return null;
            return (
              <Container key={index}>
                <Container>{filter === filtered && <Dot src={assets.dotBlue} />}</Container>
                <TextButton
                  key={filter}
                  onClick={() => setFiltered(filter)}
                  margin={isMobileWidth ? "15px 30px 15px 0" : "15px 60px 15px 0"}
                  opacity={filter === filtered ? 1 : 0.5}
                  fontSize={fontSizes.h4.default}
                >
                  {filter}
                </TextButton>
              </Container>
            );
          })}
      </FilterContainer>
    );
  };

  const FilterContent = () => {
    if (filterBlogs == null) return null;
    const itemsToLoad = filterBlogs.slice(0, noOfItemsToLoad);
    return (
      <Content>
        <StyledMasonry
          columns={isMobileWidth ? 1 : isTabletWidth ? 2 : 3}
          spacing={isMobileWidth ? 2 : 5}
          defaultHeight={2610}
        >
          {itemsToLoad.map((blog, index) => {
            if (blog == null) return null;
            return (
              <Link to={blog.slug?.current as string}>
                <BlogInnerPreviewContent
                  media={blog.previewMedia}
                  title={blog.title}
                  category={blog.category}
                  key={index}
                  isHome
                />
              </Link>
            );
          })}
        </StyledMasonry>
        {noOfItemsToLoad <= allBlogs.length && (
          <Container justifyContent="center" margin="100px auto auto auto">
            <TextButton
              onClick={handleLoadMoreClick}
              fontSize={fontSizes.h2.mobile}
              color="flightBlue"
              underlined
            >
              Load More
            </TextButton>
          </Container>
        )}
      </Content>
    );
  };

  const AllContent = () => {
    const itemsToLoad = allBlogs.slice(0, noOfItemsToLoad);
    return (
      <Content>
        <StyledMasonry
          columns={isMobileWidth ? 1 : isTabletWidth ? 1 : 3}
          spacing={isMobileWidth ? 2 : 5}
          defaultHeight={2610}
        >
          {itemsToLoad.map((blog, index) => {
            if (blog == null) return null;
            return (
              <Link to={blog.slug?.current as string} key={index}>
                <BlogInnerPreviewContent
                  media={blog.previewMedia}
                  title={blog.title}
                  category={blog.category}
                  isHome
                />
              </Link>
            );
          })}
        </StyledMasonry>
        {noOfItemsToLoad <= allBlogs.length && (
          <Container justifyContent="center" margin="100px auto auto auto">
            <TextButton
              onClick={handleLoadMoreClick}
              fontSize={fontSizes.h2.mobile}
              color="flightBlue"
              underlined
            >
              Load More
            </TextButton>
          </Container>
        )}
      </Content>
    );
  };

  return (
    <Container flexDirection="column">
      <Hero heroData={homeQuery.hero} />
    </Container>
  );
}
